<template>



  <Workspaces />


</template>

<script>

import Workspaces from "@/components/view/Workspaces";

export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
Workspaces,
 
  },
  metaInfo: {
    title: "Over KantoorFlex | flexplekken in Nederland ",
    metaInfo:'Op zoek naar een flexplek? Of wil je jouw flexibele kantoorruimte verhuren? Flexwerkplek huur of verhuur je bij Kantoorflex. Door heel Nederland. ',
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>