<template>
  <GmapMap
    ref="mapRef"
    :center="{ lat: 52.092876, lng: 5.10448 }"
    :zoom="6"
    map-type-id="terrain"
    style="width: 100%; height: 650px; margin-top: 6-px"
  >
    <GmapMarker
      :key="index"
      v-for="(m, index) in savedLocations"
      :position="getPosition(m)"
      @click="toggleInfoWindow(m)"
      :icon="markerOptions"
    />

    <gmap-info-window
      v-if="!!selectedHouse"
      :options="infoOptions"
      :position="infoWindowPos"
      :opened="!!selectedHouse"
      @closeclick="selectedHouse = null"
    >
      <div class="container-fluid" style="width: 320px">
        <div class="row">
          <div class="col-12" v-if="selectedHouse.images.length">
            <img
              height="80px;"
              width="80px"
              :src="
                selectedHouse.images
                  ? `https://server.kantoorflex.nl/static/${selectedHouse.images[0].filename}`
                  : `@/assets/logo.png`
              "
              class="img-fluid"
            />
          </div>
          <div class="col-12">
            <p class="mb-0 mt-3 font-weight-bold address">
              Prijs:€ {{ selectedHouse.prijs }}
              <br />
              Adres: {{ selectedHouse.adres }}
            </p>

            <a href="https://kantoorflex.nl/Contact">
              <button
                class="btn text-white font-weight-bold bg-dark-green learn-more mt-3 px-3"
              >
                Neem contact op
              </button> </a
            ><br />
          </div>
        </div>
      </div>
    </gmap-info-window>
  </GmapMap>
</template>

<script>
  import { gmapApi } from 'vue2-google-maps';
  const mapMarker = require('@/assets/marker/marker.png');
  export default {
    name: 'Map',
    props: ['savedLocations'],
    data() {
      return {
        markerOptions: {
          url: mapMarker,
          //size: { width: 25.6, height: 25.6, f: "px", b: "px" },
          scaledSize: { width: 50.6, height: 50.6, f: 'px', b: 'px' },
        },
        image: `url(${require('@/assets/logo.png')})`,
        currentLocation: null,
        map: null,
        selectedHouse: null,
        errored: false,
        infoContent: '',
        infoWindowPos: {
          lat: 0,
          lng: 0,
        },

        infoOptions: {
          pixelOffset: {
            width: 0,
            height: -35,
          },
        },
      };
    },
    async mounted() {
      this.map = await this.$refs.mapRef.$mapPromise;
    },
    methods: {
      setAllMarkerCenter() {
        const bounds = new this.google.maps.LatLngBounds();
        this.savedLocations.forEach(marker => {
          bounds.extend({
            lat: parseFloat(marker.lat_coord),
            lng: parseFloat(marker.long_coord),
          });
        });
        this.$refs.mapRef.fitBounds(bounds);
      },
      centerMarker(marker, zoom = 20) {
        const bounds = new this.google.maps.LatLngBounds();
        bounds.extend({
          lat: parseFloat(marker.lat),
          lng: parseFloat(marker.lng),
        });
        this.map.fitBounds(bounds);
        this.map.setZoom(zoom);
      },
      getPosition(location) {
        return (
          this.google &&
          new this.google.maps.LatLng(location.lat_coord, location.long_coord)
        );
      },
      toggleInfoWindow(marker) {
        this.selectedHouse = marker;
        this.infoWindowPos = this.getPosition(marker);
      },
    },
    computed: {
      google: gmapApi,
      averageGeolocation() {
        if (this.markers.length === 0) {
          // Center of the netherlands
          return { lat: parseFloat(52.092876), lng: parseFloat(5.10448) };
        }
        if (this.markers.length === 1) {
          return {
            lat: parseFloat(this.markers[0].lat),
            lng: parseFloat(this.markers[0].lng),
          };
        }

        let x = 0.0;
        let y = 0.0;
        let z = 0.0;
        this.markers.forEach(marker => {
          let latitude = (marker.lat * Math.PI) / 180;
          let longitude = (marker.lng * Math.PI) / 180;

          x += Math.cos(latitude) * Math.cos(longitude);
          y += Math.cos(latitude) * Math.sin(longitude);
          z += Math.sin(latitude);
        });

        let total = this.markers.length;

        x = x / total;
        y = y / total;
        z = z / total;

        let centralLongitude = Math.atan2(y, x);
        let centralSquareRoot = Math.sqrt(x * x + y * y);
        let centralLatitude = Math.atan2(z, centralSquareRoot);

        return {
          lat: parseFloat((centralLatitude * 180) / Math.PI),
          lng: parseFloat((centralLongitude * 180) / Math.PI),
        };
      },
      getImgUrl(marker) {
        var images = require.contetn('../../assets/', false, /\.png$/);
        return images('./' + marker + '.png');
      },
      //  getCurrentLocation: {},
    },
  };
</script>

<style scoped>
  .header {
    font-size: 15px;
    font-weight: 900;
  }
  .available {
    font-size: 12px;
    background-color: #20b2aa !important;
  }
  .box {
    cursor: pointer;
  }
  .text-dark-green {
    color: rgb(0, 51, 47);
  }
  .bg-dark-green {
    background-color: rgb(0, 51, 52);
  }
  .learn-more {
    font-size: 15px;
  }
  .phone-no {
    font-size: 12px;
  }
  .miles {
    font-size: 12px;
  }
  .address {
    font-size: 12px;
  }
  .popup {
    width: 200px;
    overflow: -moz-hidden-unscrollable;
    position: relative;
    box-shadow: 0 15px 20px rgb(131, 131, 131);
    z-index: 1;
  }
  .down-arrow {
    --width: 25px;
    width: var(--width);
    height: var(--width);
    position: absolute;
    bottom: calc(-1 * var(--width) / 2);
    left: calc(50% - var(--width) / 2);
    transform: rotate(45deg);
    z-index: -1;
    background-color: white;
  }

  input {
    width: 10px;
  }
  input[type='text'] {
    width: 15%;
    margin: 8px 0;
    box-sizing: border-box;
  }
  .scroll {
    overflow: auto;
    max-height: calc(100vh - 225px);
  }
</style>
