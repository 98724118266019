<template>
  <div>
    <v-divider> </v-divider>
    <v-container>
      <div style="margin-top: 20px"></div>
      <div style="margin-bottom:20px;">
        <v-btn @click="viewmore = !viewmore">
          <v-icon color="primary"> mdi-filter-variant </v-icon>
          {{ viewmore ? "Minder" : "Meer" }}
        </v-btn>
      </div>
      <v-card>
        <v-row style="margin-right: 50px; margin-left: 50px">
          <v-col class="d-flex" cols="12" sm="4">
            <v-select
              :items="cities"
              chips
              hide-details
              dense
              v-model="selectedCities"
              label="Selecteer Stad"
              multiple
              outlined
            ></v-select>
          </v-col>

          <v-col class="d-flex" cols="12" sm="4">
            <v-select
              :items="categories"
              item-value="categorie_id"
              v-model="selectedCategories"
              hide-details
              item-text="categorie"
              chips
              dense
              label="Selecteer categorie"
              multiple
              outlined
            ></v-select>
          </v-col>

          <v-col class="d-flex" cols="12" sm="3">
            <v-select
              :items="facilities"
              item-value="facilitie_id"
              v-model="selectedFacilities"
              item-text="facilitie"
              hide-details
              chips
              dense
              label="Selecteer faciliteit"
              multiple
              outlined
            ></v-select>
          </v-col>

          <v-col class="d-flex" cols="12" sm="1">
            <v-btn
              @click="map = !map"
              icon
              :color="map ? 'secondary' : 'primary'"
            >
              <v-icon>mdi-map-marker </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row style="margin-right: 50px; margin-left: 50px" v-if="viewmore">
          <v-col
            cols="12"
            sm="4"
            class="mx-auto"
            v-if="prices[0] != Infinity || prices[1] != -Infinity"
          >
            <div class="d-flex">
              <p>Prijs:</p>
              <v-range-slider
                hide-details="auto"
                thumb-label="true"
                thumb-size="30px"
                :max="prices[1]"
                step="100"
                :min="prices[0]"
                v-model="filteredPrices"
              ></v-range-slider>
            </div>
          </v-col>

          <v-col class="d-flex" cols="12" sm="4">
            <v-select
              item-text="facilitie"
              hide-details
              chips
              dense
              label="Aantal vierkante meters"
              multiple
              outlined
            ></v-select>
          </v-col>

          <v-col class="d-flex" cols="12" sm="3">
            <v-select
              :items="items"
              hide-details
              chips
              dense
              label="Selecteer beschikbaarheid"
              multiple
              outlined
            ></v-select>
          </v-col>
          <v-col class="d-flex" cols="12" sm="1">
            <v-icon @click="resetBrowser()" color="error">mdi-restore </v-icon>
          </v-col>
        </v-row>
      </v-card>
      <div style="margin-top: 20px" v-if="!map">
        <v-row>
          <v-col cols="12">
            <h6
              v-if="
                !filteredPlaces.length &&
                  (selectedCategories.length || selectedFacilities.length)
              "
            >
              Geen locatie gevonden!
            </h6>
            <h6
              class="mb-2"
              v-else-if="notFoundCities !== null && notFoundCities.length"
            >
              <p style="color: red">
                Er zijn helaas geen locaties in
                {{ this.notFoundCities.join(", ") }}
              </p>

              <h5>Overige locaties</h5>
            </h6>
            <template>
              <v-row>
                <v-col
                  v-for="place in filteredPlaces"
                  :key="place.office_id"
                  class="d-flex flex-column"
                  sm="6"
                  lg="3"
                  md="4"
                >
                  <v-card rounded class="pb-2 flex d-flex flex-column">
                    <b-link
                      class="d-block card-image-link flex"
                      :to="`/SingleLocation/${place.office_id}`"
                    >
                      <v-img
                        :src="
                          place.images[0]
                            ? `https://server.kantoorflex.nl/static/${place.images[0].filename}`
                            : require('@/assets/logo.png')
                        "
                        img-alt="Image"
                        img-top
                        tag="article"
                        class="h-100 card-image body-1"
                      >
                      </v-img>
                    </b-link>
                    <v-card-title class="py-0 mb-3">
                      <b-link
                        :to="`/SingleLocation/${place.office_id}`"
                        class="link"
                        >{{ place.officeName }}</b-link
                      >
                    </v-card-title>
                    <v-card-subtitle class="pb-0 mb-2" style="color: #949ca5">
                      <v-icon
                        v-if="place.type == 'Kantoor'"
                        color="primary"
                        dark
                      >
                        mdi-office-building
                      </v-icon>

                      <v-icon
                        v-if="place.type == 'Vergaderruimte'"
                        color="primary"
                        dark
                      >
                        mdi-human-male-board
                      </v-icon>

                      <v-icon
                        v-if="place.type == 'Flexplek'"
                        color="primary"
                        dark
                      >
                        mdi-chair-rolling
                      </v-icon>

                      {{ place.type }}
                      <br />
                      {{ place.adres }}</v-card-subtitle
                    >
                    <v-card-subtitle class="py-0">
                      <div style="margin-top: 20px">
                        <router-link :to="`/SingleLocation/${place.office_id}`">
                          <v-btn color="#088dd3" class="ma-2 white--text" block>
                            Bekijken</v-btn
                          ></router-link
                        >
                      </div>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-col>
        </v-row>
      </div>

      <div v-show="map" style="margin-top: 20px">
        <AllOfficesMap :saved-locations="filteredPlaces" />
      </div>
    </v-container>
  </div>
</template>

<script>
import apiOffice from "../api/apiOffice";
import AllOfficesMap from "./AllOfficesMap";
import { BLink } from "bootstrap-vue";
export default {
  name: "Workspaces",
  data() {
    return {
      viewmore: false,
      items: ["Beschikbaar"],
      map: false,
      facilities: [],
      categories: [],
      places: [],
      cities: [],
      selectedCities: [],
      selectedCategories: [],
      selectedFacilities: [],
      notFoundCities: [],
      prices: [Infinity, -Infinity],
      filteredPrices: [],
    };
  },
  components: {
    BLink,
    AllOfficesMap,
  },
  async mounted() {
    apiOffice.getOptions().then((data) => {
      this.facilities = data.facilities;
    });
    apiOffice.getCategories().then((data) => {
      this.categories = data.categories;
    });

    apiOffice.getAllPlaces().then((data) => {
      this.setDataForFilters(data);
      this.places = data;
    });
  },

  methods: {
    resetBrowser() {
      window.location.reload();
    },

    setSelectedCities(citiesMap) {
      let urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has("cities")) {
        const cities = urlParams.get("cities").split(",");
        cities.forEach((city) =>
          citiesMap.has(city)
            ? this.selectedCities.push(city)
            : this.notFoundCities.push(city)
        );
      } else this.notFoundCities = null;
    },
    setDataForFilters(places) {
      const citiesMap = new Map();
      places.forEach((place) => {
        !place.city || citiesMap.set(place.city, "");
        if (!place.price) return;
        const price = parseFloat(place.price);
        this.prices[0] = Math.min(this.prices[0], price);
        this.prices[1] = Math.max(this.prices[1], price);
      });
      this.filteredPrices = [...this.prices];
      this.cities = [...citiesMap.keys()];
      this.setSelectedCities(citiesMap);
    },
  },

  computed: {
    filteredPlaces() {
      let filtered = this.selectedCities.length
        ? this.places.filter((place) =>
            this.selectedCities.includes(place.city)
          )
        : this.places;
      filtered = this.selectedCategories.length
        ? filtered.filter((place) => {
            return place.categories.some(({ categorie_id }) =>
              this.selectedCategories.includes(categorie_id)
            );
          })
        : filtered;
      filtered = this.selectedFacilities.length
        ? filtered.filter((place) => {
            return place.facilities.some(({ facilitie_id }) =>
              this.selectedFacilities.includes(facilitie_id)
            );
          })
        : filtered;
      console.log(filtered);
      return filtered.filter(({ price }) => {
        if (!price) return true;
        return (
          this.filteredPrices[0] <= parseFloat(price) &&
          this.filteredPrices[1] >= parseFloat(price)
        );
      });
    },
  },
};
</script>

<style scoped>
.space {
  margin-top: 5px;
}
.link {
  color: rgba(79, 89, 98);
  text-decoration: none;
  transition: color 300ms ease-in-out;
}
.link:hover {
  color: rgba(79, 89, 98, 0.5);
}
.card-image-link {
  overflow: hidden;
  cursor: pointer;
}
.card-image {
  transition: 300ms;
}
.card-image:hover {
  transform: scale(1.2);
}
</style>
